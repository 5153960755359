import React from 'react';
import TitleIcon from '@material-ui/icons/Title';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import MemoryIcon from '@material-ui/icons/Memory';
import LanguageIcon from '@material-ui/icons/Language';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Admin, Resource, fetchUtils } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import { HashRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import Dashboard from './scenes/Dashboard/Dashboard';
import customRoutes from './customRoutes';
import CustomLayout from './components/CustomLayout';
import {
  CountryList,
  CountryEdit,
  CountryCreate,
  CountryShow,
} from './scenes/Countries';
import {
  WidDataCreate,
  WidDataEdit,
  WidDataList,
  WidDataShow,
} from './scenes/WidData';
import { AppContextProvider } from './components/Context/AppContext';
import {
  VariablesTypesEdit,
  VariablesTypesCreate,
  VariablesTypesList,
  VariablesTypesShow,
} from './scenes/VariablesTypes';
import {
  VariablesNamesCreate,
  VariablesNamesEdit,
  VariablesNamesList,
  VariablesNamesShow,
} from './scenes/VariablesNames';
import {
  VariablesNotesCreate,
  VariablesNotesEdit,
  VariablesNotesList,
  VariablesNotesShow,
} from './scenes/VariablesNotes';
import {
  VariablesPopsCreate,
  VariablesPopsEdit,
  VariablesPopsList,
  VariablesPopsShow,
} from './scenes/VariablesPops';
import {
  VariablesAgesCreate,
  VariablesAgesEdit,
  VariablesAgesList,
  VariablesAgesShow,
} from './scenes/VariablesAges';
import {
  VariablesUnitsCreate,
  VariablesUnitsEdit,
  VariablesUnitsList,
  VariablesUnitsShow,
} from './scenes/VariablesUnits';
import {
  VariablesTreeCreate,
  VariablesTreeEdit,
  VariablesTreeList,
  VariablesTreeShow,
} from './scenes/VariablesTree/VariablesTypes';
import customTheme from './theme';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  // add your own headers here
  // options.headers.set('Content-Range', 'countries 0-24/319');
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider(
  `${process.env.REACT_APP_API_URL || 'http://localhost:5000'}/api`,
  httpClient
);
const queryClient = new QueryClient();

const App = () => (
  <QueryClientProvider client={queryClient}>
    <AppContextProvider>
      <HashRouter>
        <Admin
          theme={customTheme}
          layout={CustomLayout}
          dataProvider={dataProvider}
          dashboard={Dashboard}
          customRoutes={customRoutes}
        >
          <Resource
            name="wid_data"
            icon={MemoryIcon}
            list={WidDataList}
            edit={WidDataEdit}
            create={WidDataCreate}
            show={WidDataShow}
          />
          <Resource
            name="countries"
            icon={LanguageIcon}
            list={CountryList}
            edit={CountryEdit}
            create={CountryCreate}
            show={CountryShow}
          />
          <Resource
            name="variables_types"
            icon={TitleIcon}
            list={VariablesTypesList}
            edit={VariablesTypesEdit}
            create={VariablesTypesCreate}
            show={VariablesTypesShow}
          />
          <Resource
            name="variables_names"
            icon={AssignmentIcon}
            list={VariablesNamesList}
            edit={VariablesNamesEdit}
            create={VariablesNamesCreate}
            show={VariablesNamesShow}
          />
          <Resource
            name="variables_notes"
            icon={MenuBookIcon}
            list={VariablesNotesList}
            edit={VariablesNotesEdit}
            create={VariablesNotesCreate}
            show={VariablesNotesShow}
          />
          <Resource
            name="variables_pops"
            icon={NaturePeopleIcon}
            list={VariablesPopsList}
            edit={VariablesPopsEdit}
            create={VariablesPopsCreate}
            show={VariablesPopsShow}
          />
          <Resource
            name="variables_ages"
            icon={LocalOfferIcon}
            list={VariablesAgesList}
            edit={VariablesAgesEdit}
            create={VariablesAgesCreate}
            show={VariablesAgesShow}
          />
          <Resource
            name="variables_units"
            icon={MonetizationOnIcon}
            list={VariablesUnitsList}
            edit={VariablesUnitsEdit}
            create={VariablesUnitsCreate}
            show={VariablesUnitsShow}
          />
          <Resource
            options={{ label: 'Variables tree' }}
            name="variables_tree"
            icon={AccountTreeIcon}
            list={VariablesTreeList}
            edit={VariablesTreeEdit}
            create={VariablesTreeCreate}
            show={VariablesTreeShow}
          />
        </Admin>
      </HashRouter>
    </AppContextProvider>
  </QueryClientProvider>
);
export default withAuthenticationRequired(App);
