import React from 'react';
import { forwardRef, useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';
import { ListItem, Menu } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { AccountCircle } from '@material-ui/icons';
import { useAuth0 } from '@auth0/auth0-react';

const CustomUserMenu = forwardRef((props, ref) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { user, logout } = useAuth0();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <AccountCircle style={{ color: 'white' }} />
      </Button>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ListItem divider>{user?.name}</ListItem>
        <MenuItem
          onClick={() =>
            logout({ logoutParams: { returnTo: window.location.origin } })
          }
        >
          <ExitIcon style={{ marginRight: 10 }} /> Logout
        </MenuItem>
      </Menu>
    </div>
  );
});

export default CustomUserMenu;
